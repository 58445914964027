.paddingall{
    padding: 10px;
}

.price{
    font-weight: 600;
    font-size: 16px;
}


.pricename{
    font-size: 16px;
}
#iconstyle{
    margin-top: 20px;
    margin-left: 20px;
}

.dashicon{
    text-align:  center;
}

.icon{
    color: #fff;
    text-align: center;
}

.card-title{
    color: #fff;
    text-align: center;
}

.card-text{
    color: #fff;
    text-align: center;
}

#gradientCard{
    background: #1976D2
}

#gradientCard:hover{
    background: #2688e9
}

/* LoadingOverlay.css */

.loading-overlay-container {
    position: relative;
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255,0.9); /* Adjust the opacity here (0.1 = 10%) */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
  }
  
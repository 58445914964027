/* .form {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
  }
  

  
  .form-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  
  .form-label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 10px;
  }
  
  button[type="submit"] {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
   */


   .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
  }
  
  .form-row {
    display: flex;
    flex-direction: column;
  }
  
  .form-label {
    margin-bottom: 0.4rem;
    font-size: 14px;
  }
  
  .form-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 11px;
  }
  
  button[type="submit"] {
    padding: 0.5rem 1rem;
    width: 15%;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    
  }
  
  .form h4 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-top: 5;
    margin-bottom: 5px;
  }